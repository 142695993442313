import React, {Component} from 'react'
import { Image } from "antd";
import $ from 'jquery'

import scBanner from '../../images/sc-bannner.jpg'
import scProgramme from "../../images/sc-programme.png";
import scAdvantage from "../../images/sc-advantage.png"
import scPian from "../../images/sc-pian.jpg"

class SupplyChain extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 'sc',
      pianWidth: 0,
      contentWidth: 0,
      openDuration: 4000
    }
    window.scrollTo({
      top:0
    })
  }

  componentDidMount() {
    this.setState({
      pianWidth: 970
    }, () => {
    })
    window.onscroll = this.listenScroll
  }

  componentWillUnmount() {
    window.onscroll = null
    const openAnim = this.state.openAnim
    clearInterval(openAnim)
    this.setState({
      openAnim: null
    })
  }

  listenScroll = () => {
    let imgTop = $("#pian-img").offset().top
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    if (imgTop - scrollTop <= 800 && this.state.contentWidth === 0) {
      this.startOpen()
    }
  }

  startOpen() {
    const _this = this
    const openStep = _this.state.pianWidth / 100
    const openAnim = setInterval(() => {
      if (_this.state.contentWidth >= _this.state.pianWidth) {
        window.onscroll = null
        clearInterval(openAnim)
        return false
      }
      _this.setState({
        contentWidth: _this.state.contentWidth + openStep
      })
    }, _this.state.openDuration / 100)
    this.setState({
      openAnim: openAnim
    })
  }

  render() {
    return (
      <div className="content-box sc-content-box">
        <div className="banner-box">
          <Image className="banner-img" src={scBanner} preview={false}/>
        </div>
        <div className="content-cell">
          <div className="content-cell-title">
            <div className="content-cell-title-cn">
              供应链<span className="font-red">解决方案</span>
            </div>
            <div className="content-cell-title-en">
              Supply chain solutions
            </div>
          </div>
          <div className="content-cell-content">
            <Image className="programme-img" src={scProgramme} preview={false}/>
          </div>
        </div>
        <div className="content-cell">
          <div className="content-cell-title">
            <div className="content-cell-title-cn">
              产品<span className="font-red">优势</span>
            </div>
            <div className="content-cell-title-en">
              Product advantages
            </div>
          </div>
          <div className="content-cell-content">
            <Image className="advantage-img" src={scAdvantage} preview={false}/>
          </div>
        </div>
        <div className="content-cell pianning-box">
          <div className="content-cell-title">
            <div className="content-cell-title-cn">
              战略<span className="font-red">规划</span>
            </div>
            <div className="content-cell-title-en">
              Strategic pianning
            </div>
          </div>
          <div className="content-cell-content" style={{width:this.state.contentWidth}}>
            <Image className="pian-img" id="pian-img" src={scPian} preview={false} width={this.state.pianWidth}/>
          </div>
        </div>
      </div>
    )
  }
}

export default (props) => <SupplyChain {...props} key={props.location.pathname}/>
