import React, {Component} from 'react'
import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'
import $ from 'jquery'

// Router List
import Home from './containers/Home'
import SupplyChain from './containers/SupplyChain'
import NewMedia from './containers/NewMedia'

// UI Component
import {Layout, Image, Input, Form, Button, message, Popover} from 'antd'
import 'moment/locale/zh-cn'

// Base Css
import 'antd/dist/antd.less'
import './style/app.pcss'

import Logo from './images/logo.png'
import qrCode from './images/qrcode.jpg'
import addIcon from './images/add-icon.png'
import email from './images/email-icon.png'
import weChatIcon from './images/wechat-icon@2x.png'
import contactIcon from './images/contact-icon@2x.png'
import goTopIcon from './images/go-top-icon@2x.png'
import homeIcon from './images/home-icon@2x.png'
import nmIcon from './images/nm-icon@2x.png'
import scIcon from './images/sc-icon@2x.png'

const {Header, Footer, Content} = Layout

const homePage = process.env.PUBLIC_URL
const isDevelop = process.env.NODE_ENV === 'development'
const baseUrl = isDevelop ? "" : homePage

let that

class App extends Component {
  constructor(props) {
    super(props);
    that = this
    this.state = {
      wechatVisible: false,
      contactVisible: false,
      activeTab: 'home',
      submitUrl: "https://test.taoxiangche.com/official/index/contact-us-v3",
      history: {},
      goTopShow: false,
      scrollTopTimer: null,
      currentRoute: "/",
    }
  }

  componentDidMount() {
    const url = window.location.href
    if (url.indexOf("#") > 0) {
      this.changeContent("contact")
    }
    // console.log(document.getElementById('content-box'))
    // ReactDOM.render((
    //   <HashRouter>
    //
    //   </HashRouter>
    // ), document.getElementById('content-box'))
    const scrollTopTimer = setInterval(() => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop >= 400 && !this.state.goTopShow) {
        this.setState({
          goTopShow: true
        })
      } else if (scrollTop >= 400 && this.state.goTopShow) {
        return false
      } else {
        this.setState({
          goTopShow: false
        })
      }
    }, 500)
    this.setState({
      scrollTopTimer: scrollTopTimer
    })
  }

  componentWillUnmount() {
    const scrollTopTimer = this.state.scrollTopTimer
    clearInterval(scrollTopTimer)
    this.setState({
      scrollTopTimer: null
    })
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    // console.log("###", this.state)
    // console.log("@@@", nextState)
  }

  getUrlParam(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    let r = window.location.search.substr(1).match(reg);  //匹配目标参数
    if (r != null) return unescape(r[2]);
    return null; //返回参数值
  }

  setCurrentRoute(route) {
    let activeTab = ""
    switch (route) {
      case "/":
        activeTab = "home"
        break;
      case "/sc":
        activeTab = "new"
        break;
      case "/nm":
        activeTab = "supply"
        break;
      default:
        activeTab = "home"
        break;
    }
    that.setState({
      currentRoute: route,
      activeTab: activeTab
    })
  }

  goTop() {
    window.scrollTo({
      top: 0
    })
  }

  render() {
    const submitInfo = (data) => {
      fetch(this.state.submitUrl, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(data)
      }).then(response => response.json())
        .then((res) => {
          if (res.code === 0) {
            message.success("提交成功")
          } else {
            message.error(res.message)
          }
        })
        .catch(err => {
          console.log("err:", err)
        })
    }
    return (
      <Router>
        <Layout className="layout-box">
          <Header className="header-box" style={{
            display: this.state.currentRoute === "/" ? "flex" : "none"
          }}>
            <div className="header-content-cell">
              <div className="logo-box">
                <img className="logo-image" src={Logo} alt="Logo"/>
              </div>
              <div className="tab-box">
            <span className={this.state.activeTab === 'home' ? 'tab-item tab-selected' : 'tab-item'}
                  onClick={() => {
                    this.changeContent('home')
                  }}
            >首页</span>
                <span className={this.state.activeTab === 'new' ? 'tab-item tab-selected' : 'tab-item'}
                      onClick={() => {
                        this.changeContent('new')
                      }}
                ><Link to={baseUrl + "/nm"} onClick={() => {
                  this.setCurrentRoute("/nm")
                }}>新媒体业务</Link></span>
                <span className={this.state.activeTab === 'supply' ? 'tab-item tab-selected' : 'tab-item'}
                      onClick={() => {
                        this.changeContent('supply')
                      }}
                ><Link to={baseUrl + "/sc"} onClick={() => {
                  this.setCurrentRoute("/sc")
                }}>汽车供应链业务</Link>
            </span>
                {/*<span className={this.state.activeTab === 'company' ? 'tab-item tab-selected' : 'tab-item'}*/}
                {/*      onClick={() => {*/}
                {/*        this.changeContent('company')*/}
                {/*      }*/}
                {/*      }*/}
                {/*>公司介绍</span>*/}
                <span className={this.state.activeTab === 'contact' ? 'tab-item tab-selected' : 'tab-item'}
                      onClick={() => {
                        this.changeContent('contact')
                      }
                      }
                >联系我们</span>
              </div>
            </div>
          </Header>
          <Content>
            <div id={"content-box" + (this.state.currentRoute === "/" ? "" : " no-margin")}>
              <Switch>
                {/*<Route exact path={baseUrl + "/"} component={Home}/>*/}
                <Route exact path={baseUrl + "/"} render={(props) => <Home {...props} setCurrentRoute={this.setCurrentRoute}/>} />
                <Route exact path={baseUrl + "/sc"} render={(props) => <SupplyChain {...props} setCurrentRoute={this.setCurrentRoute}/>}/>
                <Route exact path={baseUrl + "/nm"} render={(props) => <NewMedia {...props} contactUs={this.changeContent} setCurrentRoute={this.setCurrentRoute}/>}/>
              </Switch>
            </div>
          </Content>
          <Footer style={{
            display: this.state.currentRoute === "/" ? "block" : "none"
          }}>
            {/* <div className="contact-us-box" id="contactUs">
              <div className="contact-us-title">
                联系我们
              </div>
              <div className="contact-input-box">
                <Form className="contact-input-form" name="customerInfo" onFinish={submitInfo}>
                  <Form.Item name="customerName" rules={[{required: true, message: "请填写您的称呼"}]}>
                    <Input className="contact-input" placeholder="您怎么称呼" size="large"/>
                  </Form.Item>
                  <Form.Item name="customerPhone" rules={[{required: true, message: "请填写您的手机号"}]}>
                    <Input className="contact-input" placeholder="请输入您的手机号" size="large" maxLength={11}/>
                  </Form.Item>
                  <Form.Item name="desc">
                    <Input className="contact-input input-desc" placeholder="请输入您的需求" size="large"/>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" size="middle" danger={true} htmlType="submit">提交</Button>
                  </Form.Item>
                </Form>
              </div>
            </div> */}
            <div className="footer-box">
              <div className="left-info-box">
                <div className="second-row">
                  <div className="info-cell logo-cell">
                    <Image className="logo-img" src={Logo} preview={false}/>
                  </div>
                  <div className="info-cell">
                    <Image className="info-icon" src={addIcon} preview={false}/>
                    <div className="info-content">
                      ：北京市朝阳区酒仙桥路14号兆维工业园C3楼2层车时间
                    </div>
                  </div>
                  <div className="info-cell">
                    <Image className="info-icon" src={email}/>
                    <div className="info-content">
                      ：info@cheshijian.cn
                    </div>
                  </div>
                </div>
                <div className="third-row">
                  {/* <span>Copyright © 2020 NiPic.com All Rights Reserved　版权所有·车时间　京ICP备18000440号</span> */}
                  
                  <span>Copyright © 天津车时间科技服务有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index">津ICP备2022004171号</a></span>
                </div>
              </div>
              <div className="right-info-box">
                <div className="qrcode-box">
                  <Image className="qrcode-image" src={qrCode} preview={false}/>
                </div>
                <div className="qrcode-desc-box">
                  打开微信扫一扫<br/>关注公众号
                </div>
              </div>
            </div>
          </Footer>
          <div className="sidebar-box">
            <div className={"sidebar-item" + (this.state.wechatVisible ? " selected-sidebar-item" : "")}>
              <Popover
                content={
                  <div className="sidebar-qrcode-cell">
                    <Image className="sidebar-qrcode-image" src={qrCode} preview={false}/>
                    <div className="sidebar-qrcode-desc">打开微信扫一扫<br/>关注公众号</div>
                  </div>
                }
                trigger="click"
                arrowPointAtCenter
                placement="left"
                visible={this.state.wechatVisible}
                onVisibleChange={this.weChatVisibleChange}
              >
                <Image src={weChatIcon} className="sidebar-icon" preview={false}/>
              </Popover>
            </div>
            <div className={"sidebar-item" + (this.state.contactVisible ? " selected-sidebar-item" : "")}>
              <Popover
                className="contact-us-pop"
                content={<div className="sidebar-contact-us-btn" onClick={() => {
                  this.changeContent("contact")
                }}>联系我们</div>}
                trigger="click"
                arrowPointAtCenter
                placement="left"
                visible={this.state.contactVisible}
                onVisibleChange={this.contactVisibleChange}
              >
                <Image src={contactIcon} className="sidebar-icon" preview={false}/>
              </Popover>
            </div>
            <Link to={baseUrl + "/nm"} onClick={() => {
              this.setCurrentRoute("/nm")
            }}>
              <div
                className={"sidebar-item" +
                (this.state.currentRoute !== "/nm" &&
                this.state.currentRoute !== "/" ?
                  " show-item" : " hide-item")
                }
                onClick={this.goTop}>
                <Image src={nmIcon} className="sidebar-icon" preview={false}/>
              </div>
            </Link>
            <Link to={baseUrl + "/sc"} onClick={() => {
              this.setCurrentRoute("/sc")
            }}>
              <div
                className={"sidebar-item" + (this.state.currentRoute !== "/sc" &&
                this.state.currentRoute !== "/"
                  ? " show-item" : " hide-item")}
                onClick={this.goTop}>
                <Image src={scIcon} className="sidebar-icon" preview={false}/>
              </div>
            </Link>
            <Link id="go-home-icon" to={baseUrl + "/"} onClick={() => {
              this.setCurrentRoute("/")
            }}>
              <div className={"sidebar-item" + (this.state.currentRoute !== "/" ? " show-item" : " hide-item")}>
                <Image src={homeIcon} className="sidebar-icon" preview={false}/>
              </div>
            </Link>
            <div className={"sidebar-item go-top-item" + (this.state.goTopShow ? " go-top-show" : "")}
                 onClick={this.goTop}>
              <Image src={goTopIcon} className="sidebar-icon" preview={false}/>
            </div>
          </div>
        </Layout>
      </Router>
    )
  }

  weChatVisibleChange = visible => {
    this.setState({
      wechatVisible: visible
    })
  }

  contactVisibleChange = visible => {
    this.setState({
      contactVisible: visible
    })
  }

  changeContent = (key) => {
    // global.params.currentRoute = key
    this.setState({
      activeTab: key
    })
    switch (key) {
      case "supply":
        break;
      case "contact":
        if (this.state.currentRoute !== "/") {
          let url = window.location.href
          const urlArr = url.split("/")
          urlArr.pop()
          url = urlArr.join("/")
          // global.params.currentRoute = "/"
          this.setState({
            currentRoute: "/",
            activeTab: "home"
          })
          window.location.href = url + "#"
        } else {
          setTimeout(() => {
            $(document).scrollTop($(document).height() - $(window).height())
          }, 1000)
        }
        break;
      default:
        break;
    }
  }
}

export default App
