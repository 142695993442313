import React, {Component} from 'react'
import {Image} from "antd";

import nmBanner from "../../images/nmBanner.jpg"
import nmIntroduction from "../../images/nmIntroduction.png"
import nmService from "../../images/nmService.png"
import nmBrandBt from "../../images/nm-benteng.png"
import nmBrandHq from "../../images/nm-hongqi.png"
import nmBrandBtLogo from "../../images/nm-benteng-logo.png"
import nmBrandHqLogo from "../../images/nm-hongqi-logo.png"
import nmBrandStoreBt from "../../images/nmBrandStoreBt.png"
import nmBrandStoreHq from "../../images/nmBrandStoreHq.png"

class NewMedia extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 'NewMedia',
      pianWidth: 0,
      contentWidth: 0,
      openDuration: 4000,
      brandIdx: 0,
      industryIdx: 3,
      industryTitle: "培训",
      industryTitles: [
        "代运营",
        "矩阵运营",
        "活动策划",
        "培训"
      ]
    }
    window.scrollTo({
      top: 0
    })
  }

  componentDidMount() {
    this.startRotation()
  }

  changeBrand(idx) {
    this.setState({
      brandIdx: idx
    })
  }

  startRotation() {
    const rotationTimer = setInterval(() => {
      const idx = (this.state.industryIdx + 1) % 4
      this.setState({
        industryIdx: idx,
        industryTitle: this.state.industryTitles[idx]
      })
    }, 1000)
    this.setState({
      rotationTimer: rotationTimer
    })
  }

  contactUs() {
    this.props.contactUs("contact")
  }

  handleMouse() {

  }

  render() {
    return (
      <div className="content-box nm-content-box">
        <div className="banner-box">
          <Image className="banner-img" src={nmBanner} preview={false}/>
        </div>
        <div className="content-cell">
          <div className="content-cell-title">
            <div className="content-cell-title-cn">
              紫媒<span className="font-red">介绍</span>
            </div>
            <div className="content-cell-title-en">
              Introduction to purple media
            </div>
          </div>
          <div className="content-cell-content">
            <Image className="programme-img" src={nmIntroduction} preview={false}/>
          </div>
        </div>
        <div className="content-cell">
          <div className="content-cell-title">
            <div className="content-cell-title-cn">
              服务<span className="font-red">介绍</span>
            </div>
            <div className="content-cell-title-en">
              Service introduction
            </div>
          </div>
          <div className="content-cell-content">
            <Image className="advantage-img" src={nmService} preview={false}/>
          </div>
        </div>
        <div className="content-cell pianning-box">
          <div className="content-cell-title">
            <div className="content-cell-title-cn">
              成功<span className="font-red">案例</span>
            </div>
            <div className="content-cell-title-en">
              Success stories
            </div>
          </div>
          <div className="content-cell-content brand-store-box">
            <div className="brand-box">
              <div className={"brand-cell" + (this.state.brandIdx === 0 ? " selected-brand" : "")} onClick={() => {
                this.changeBrand(0)
              }}>
                <Image className="brand-logo" src={nmBrandHqLogo} preview={false}/>
                <Image className="brand-car-img" src={nmBrandHq} preview={false}/>
              </div>
              <div className={"brand-cell" + (this.state.brandIdx === 1 ? " selected-brand" : "")} onClick={() => {
                this.changeBrand(1)
              }}>
                <Image className="brand-logo" src={nmBrandBtLogo} preview={false}/>
                <Image className="brand-car-img" src={nmBrandBt} preview={false}/>
              </div>
            </div>
            <div className="brand-store-box">
              <Image
                style={{display: (this.state.brandIdx === 0 ? "block" : "none")}}
                src={nmBrandStoreHq}
                preview={false}/>
              <Image
                style={{display: (this.state.brandIdx === 1 ? "block" : "none")}}
                src={nmBrandStoreBt}
                preview={false}/>
            </div>
          </div>
        </div>
        <div className="content-cell industry-box">
          <div className="content-cell-title">
            <div className="content-cell-title-cn">
              行业<span className="font-red">方案</span>
            </div>
            <div className="content-cell-title-en">
              Industry solutions
            </div>
          </div>
          <div className="content-cell-content industry-content-cell">
            <div className="industry-content">
              <div className="industry-items-box">
                <div className={"industry-item-cell" + (this.state.industryIdx === 0 ? " select-item" : "")}>
                  代运营
                </div>
                <div className={"industry-item-cell" + (this.state.industryIdx === 1 ? " select-item" : "")}>
                  矩阵运营
                </div>
                <div className={"industry-item-cell" + (this.state.industryIdx === 2 ? " select-item" : "")}>
                  活动策划
                </div>
                <div className={"industry-item-cell" + (this.state.industryIdx === 3 ? " select-item" : "")}>
                  培训
                </div>
              </div>
              <div className="industry-content-box">
                <div className="industry-title-cell"
                     onMouseEnter={() => {
                       this.handleMouse("enter")
                     }}
                     onMouseLeave={() => {
                       this.handleMouse("leave")
                     }}
                >
                  {this.state.industryTitle}
                </div>
                <div className="industry-item-cell">
                  <div className="industry-item-content-cell acting-opera-cell"
                       style={{display: this.state.industryIdx === 0 ? "block" : "none"}}>
                    <div className="first-row">
                      <span>（1）账号设置</span>
                      <span className="separate">·</span>
                      <span>（2）IP人设</span>
                      <span className="separate">·</span>
                      <span>（3）类型定位</span>
                      <span className="separate">·</span>
                      <span>（4）内容规划</span>
                    </div>
                    <div className="second-row">
                      <span>（5）执行计划及总结<span className="small-font">（执行计划、周度数据表、运营总结、报告）</span></span>
                    </div>
                    <div className="third-row">
                      <span>（6）运营工作<span className="small-font">（诊断、规划、排期、短视频流程、培训）</span></span>
                    </div>
                  </div>
                  <div className="industry-item-content-cell opera-strategy-cell"
                       style={{display: this.state.industryIdx === 1 ? "block" : "none"}}>
                    <ul className="sub-content-cell">
                      <li className="sub-content-title">
                        整体提升
                        <p className="small-font">通过运营培训提升基础账号活跃及能力，发掘潜力账号。</p>
                      </li>
                      <li className="sub-content-title">
                        标杆扶植
                        <p className="small-font">通过优质账号扶植，辐射带动矩阵总体成长。</p>
                      </li>
                      <li className="sub-content-title">
                        活动助推
                        <p className="small-font">综合厂家营销计划，平台定制专属活动，深度运营提升优质账号成长，带动矩阵活跃。</p>
                      </li>
                    </ul>
                  </div>
                  <div className="industry-item-content-cell event-planning-cell"
                       style={{display: this.state.industryIdx === 2 ? "block" : "none"}}>
                    <ul className="sub-content-cell">
                      <li className="sub-content-title">
                        话题挑战赛
                        <p className="small-font">全网造势-定制20年新款H5上市话题挑战赛，通过短视频+直播形式进行话题挑战赛</p>
                      </li>
                      <li className="sub-content-title">
                        直播PK赛
                        <p className="small-font">配货H5上市热度，在快手发起4S店销售线上直播接龙的国民级活动-百团大战。</p>
                      </li>
                      <li className="sub-content-title">
                        大咖连麦
                        <p className="small-font">车咖网红视频产出，实力解说红旗H5，提升上市热度，引老铁关注。</p>
                      </li>
                    </ul>
                  </div>
                  <div className="industry-item-content-cell tain-cell"
                       style={{display: this.state.industryIdx === 3 ? "block" : "none"}}>
                    <ul className="sub-content-cell">
                      <li className="sub-content-title">
                        初阶内容
                        <div className="small-font">
                          <p>平台简介</p>
                          <p>专修你的账号</p>
                          <p>拍摄第一条视频</p>
                          <p>直播准备</p>
                          <p>平台规范</p>
                        </div>
                      </li>
                      <li className="sub-content-title">
                        标杆扶植
                        <div className="small-font">
                          <p>你的账号需要一个人设</p>
                          <p>如何拍摄优质的视频</p>
                          <p>让你的视频更吸引用户</p>
                          <p>利用数据优化内容质量</p>
                        </div>
                      </li>
                      <li className="sub-content-title">
                        活动助推
                        <div className="small-font">
                          <p>网红级包装</p>
                          <p>短视频运营方法论</p>
                          <p>让你的直播变成一种标签</p>
                          <p>商业化运营</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="understand-more-btn"
                    onClick={() => {
                      this.contactUs()
                    }}
                  >
                    了解更多
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default (props) => <NewMedia {...props} key={props.location.pathname}/>
